import React from "react"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import PhoneInput from "react-phone-number-input/input"

import Layout from "../components/layout"
import OwlCarousel from "../components/owl-carousel"
import ConfianEnNosotros from "../components/confian-en-nosotros"

import bgHome from "../assets/old/img/cotizador/bg-home.png"
import imgHome from "../assets/old/img/cotizador/img-home-auto.png"
import bgHome2 from "../assets/old/img/cotizador/bg-home-abajo.png"
import testimoniosH1 from "../assets/old/img/testimonios/h1.jpg"
import testimoniosH2 from "../assets/old/img/testimonios/h2.jpg"
import testimoniosM1 from "../assets/old/img/testimonios/m1.jpg"
import testimoniosM2 from "../assets/old/img/testimonios/m2.jpg"
import youtube from "../assets/old/img/home/youtube.jpg"

export default class extends React.Component {
  render = () => (
    <Layout menuActivo="inicio">
      <Helmet>
        <title>Seguro para Autos</title>
        <meta
          name="description"
          content="Cotizar seguro de auto."
        />
        <link rel="preconnect" href="https://webpack.wokan.com.ar" />
      </Helmet>

      <section className="bg-w pt-3 pb-5 bghome" style={{ backgroundImage: `url(${bgHome})` }}>
        <div className="container text-center pt-2 pb-5 mb-5">
          <div className="row pb-1 mb-3">
            <div className="col-md-12 mb-30">
              <h1 className="display-4 text-uppercase">
                <span className="text-primary">¡</span>Cotiza seguro de auto online
                <span className="text-primary">!</span>{" "}
                <span role="img" aria-label="Sonrisa">
                  😊
                </span>
              </h1>
              <h2 className="h4 block-title text-center mt-1 text-color-444">
                compará y ahorrá hasta un 30%{" "}
                <span role="img" aria-label="Corneta">
                  📢
                </span>
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section className="container pb-3 cotizador-gral">
        <div className="bg-white3 box-shadow-home px-3 px-sm-5 pt-2 pb-2 text-center text-sm-left">
          <CotizacionForm />
        </div>
      </section>

      {/* Animacion */}
      <section className="bg-footer-grl" style={{ backgroundImage: `url(${bgHome2})` }}>
        <div>
          <img className="img-portada-home d-block" src={imgHome} alt="Cotizando mi auto" />
        </div>
      </section>

      {/* Aseguradoras */}
      <ConfianEnNosotros />

      {/* Process */}
      <section className="container pt-5 pb-5 mt-2">
        <h2 className="h3 block-title text-center pt-2">¿Cómo funciona?</h2>
        <div className="row py-2">
          <div className="col-lg-4 col-sm-6">
            <div className="step step-with-icon">
              <div className="step-number">1</div>
              <div className="step-icon">
                <i className="fas fa-chalkboard-teacher step-icon-size" />
              </div>
              <h3 className="step-title">Cotizas</h3>
              <p className="step-text text-sm">
                Completás tus datos y los del auto y obtené los resultados en menos de 1 minuto.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="step step-with-icon">
              <div className="step-number">2</div>
              <div className="step-icon">
                <i className="far fa-object-ungroup step-icon-size" />
              </div>
              <h3 className="step-title">Comparas</h3>
              <p className="step-text text-sm">
                En un cuadro dinámico entre las mejores aseguradoras precios y compará coberturas.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12">
            <div className="step step-with-icon">
              <div className="step-number">3</div>
              <div className="step-icon">
                <i className="far fa-handshake step-icon-size" />
              </div>
              <h3 className="step-title">Contratas</h3>
              <p className="step-text text-sm">
                Elegí online el seguró para vos y solicitá asistencia cuando quieras.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="bg-center-top bg-no-repeat bg-cover">
        <div className="container py-5">
          <h2 className="h4 block-title text-center mt-2">Lo que opinan nuestros clientes</h2>
          <OwlCarousel
            className="owl-carousel carousel-flush pt-3"
            config={{
              nav: false,
              dots: true,
              autoHeight: true,
              responsive: {
                "0": { items: 1 },
                "630": { items: 2 },
                "991": { items: 3 },
                "1200": { items: 3 },
              },
            }}
          >
            <div className="mb-30 pb-4">
              <div className="card blog-card">
                <div className="card-body">
                  <p className="text-muted testimonial-p">
                    Muy atentos, mucha paciencia para explicarme distintas alternativas para mi
                    seguro.{" "}
                  </p>
                  <h6 className="post-title city">Buenos Aires</h6>
                  <div className="rating-stars">
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star" />
                  </div>
                </div>
                <div className="card-footer">
                  <a className="post-author" href="/">
                    <div className="post-author-avatar">
                      <img src={testimoniosH1} alt="Claudio Ortega" />
                    </div>
                    <div className="post-author-name">Claudio Ortega</div>
                  </a>
                  <div className="post-meta">
                    <span>
                      <i className="fe-icon-clock" />
                      Junio, 2019
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-30 pb-4">
              <div className="card blog-card">
                <div className="card-body">
                  <p className="text-muted testimonial-p">
                    Buenas noches, ya me llegó la póliza, muchas gracias por la atención de todos,
                    por cierto muy amables… Éxitos...
                  </p>
                  <h6 className="post-title city">Córdoba</h6>
                  <div className="rating-stars">
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star active" />
                  </div>
                </div>
                <div className="card-footer">
                  <a className="post-author" href="/">
                    <div className="post-author-avatar">
                      <img src={testimoniosM1} alt="Silvia Saenz" />
                    </div>
                    <div className="post-author-name">Silvia Saenz</div>
                  </a>
                  <div className="post-meta">
                    <span>
                      <i className="fe-icon-clock" />
                      Mayo, 2019
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-30 pb-4">
              <div className="card blog-card">
                <div className="card-body">
                  <p className="text-muted testimonial-p">
                    Increible chicos, en 5 minutos ya tengo el auto aseguradooo!!! Gracias !!!
                  </p>
                  <h6 className="post-title city">Rosario</h6>
                  <div className="rating-stars">
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star active" />
                  </div>
                </div>
                <div className="card-footer">
                  <a className="post-author" href="/">
                    <div className="post-author-avatar">
                      <img src={testimoniosM2} alt="Sara Cortez" />
                    </div>
                    <div className="post-author-name">Sara Cortez</div>
                  </a>
                  <div className="post-meta">
                    <span>
                      <i className="fe-icon-clock" />
                      Abril, 2019
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-30 pb-4">
              <div className="card blog-card">
                <div className="card-body">
                  <p className="text-muted testimonial-p">La atención fue de primera, gracias.</p>
                  <h6 className="post-title city">Buenos Aires</h6>
                  <div className="rating-stars">
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star active" />
                    <i className="fe-icon-star" />
                  </div>
                </div>
                <div className="card-footer">
                  <a className="post-author" href="/">
                    <div className="post-author-avatar">
                      <img src={testimoniosH2} alt="Pedro Romanazzi" />
                    </div>
                    <div className="post-author-name">Pedro Romanazzi</div>
                  </a>
                  <div className="post-meta">
                    <span>
                      <i className="fe-icon-clock" />
                      Marzo, 2019
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>

      {/* YouTube CTA */}
      <section className="bg-secondary py-5">
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-6 col-md-7">
              <img className="d-block" src={youtube} alt="Canal de YouTube" />
            </div>
            <div className="col-xl-4 col-md-5 text-center text-md-left">
              <a
                className="video-player-button my-3 mr-3"
                href="https://www.youtube.com/channel/UCngnk9h5p6NHiDPAxsLelLg/featured"
              >
                <i className="fe-icon-play" />
              </a>
              <span className="video-player-label text-muted">Youtube - Web del Seguro</span>
              <h2 className="h3 mt-2">Miranos en YouTube</h2>
              <p className="text-muted d-none d-lg-block">
                Conoce nuestro canal de Youtube donde encontraras videos explicativos de cómo
                proceder ante un siniestro, cotizar en nuestro sitio web y mucho más.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

class CotizacionForm extends React.Component {
  state = {
    marcas: [],
    anios: [],
    modelos: [],
    versiones: [],
  }

  idsMarcasPreferidas = ["jP5wzJEBKOlBYxkM0o3@", "D@nq36z$MW2eOB.7VEK}", "nR9Nl50}oYojYXQ{1;.(", "x9zr8Z1QvW8{4qgRe;EL", "MDN0dx3o(Y()Wlnk@e;L", "M.mvrz@)qO)}WG26k(DJ", "XKkV2N$rROZ840Ll}jpQ", "MJyz(6o;pAQ1OBE3Legv", "gMkJP6qR;4NoW}B8onp1", "oN360gG81ARpAnQMK97d", "XDjdk97yEY6EYq5@n{8x", "Z7}VQv.LM4nx4g6eRxnq", "7n8e6$)MrYxoWg;.XZpx", "}P(EZ0ow5YjoYX@ldRnB", "j$wNxD;o3AXyO9K{MQ1g", "6DQqgNyj}A3GAKdRxreG", "nK.x{}jX$Ogm4N3VE(ZL", "DN0dx3o(Y(VLWlnk@e;L"]

  componentDidMount() {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = "https://www.google.com/recaptcha/api.js?render=explicit"
    document.getElementsByTagName("head")[0].appendChild(script)

    fetch(`${process.env.WOKAN_WEBPACK_ENPOINT}/data_auto/marcas`, {
      headers: {
        "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_SID}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          console.log("No se pudo obtener las marcas desde el backend.")
        } else {
          response.json().then(data => {
            data.result.forEach(marca => {
              marca.preferida = this.idsMarcasPreferidas.indexOf(marca.id) > -1
            })
            this.setState({
              marcas: data.result,
            })
            this.marcaSelect.focus()
          })
        }
      })
      .catch(err => {
        alert(
          "¿Está conectado a internet? No es posible realizar ciertas operaciones, intente nuevamente por favor."
        )
      })
  }

  cotizar(values, errorCallback, recaptchaResponse = "sin-captcha") {
    const data = {
      nombre: values.nombre,
      telefono: values.celular.replace(/^\+549?/, ""),
      codigo_postal: values.cp,
      data_auto_id: values.version,
      anio: values.anio === "0" ? new Date().getFullYear() : values.anio,
      email: values.email,
      tiene_gnc: values.gnc,
      fecha_nacimiento: "1970-01-01",
      sexo: "m",
      estado_civil: "soltero",
      es_cero: values.anio === "0" ? 1 : 0,
      tiene_rastreador: 0,
      landing: window.location.href,
      recaptcha_response: recaptchaResponse,
    }
    fetch(`${process.env.WOKAN_WEBPACK_ENPOINT}/autos/cotizacion`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_SID}`,
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.hasOwnProperty("error_captcha")) {
          const recaptchaWidgetId = window.grecaptcha.render(this.recaptchaWidget, {
            sitekey: "6LfRKzIUAAAAAMT9kN8vexdJKYhvZBUcCdZDHfwu",
            size: "invisible",
            callback: recaptchaResponse => {
              this.cotizar(values, errorCallback, recaptchaResponse)
            },
            "error-callback": () => {
              errorCallback(
                "No se pudo continuar en este momento, intente nuevamente más tarde por favor."
              )
            },
          })
          window.grecaptcha.execute(recaptchaWidgetId)
        } else if (!response.hasOwnProperty("token")) {
          errorCallback("Ocurrió un error, intente nuevamente más tarde por favor.")
        } else {
          values.token = response.token
          navigate("/resultados/", {
            state: { ...values },
          })
          // enviar el mail de cotización nueva
          const asunto = `CA (L2): ${data.email} ${data.anio} ${values.marcaDescripcion} ${values.versionDescripcion}`
          const cuerpo = `
            <p>Has recibido una nueva oportunidad comercial desde tu sitio web.</p>
            <p>
              <a href="https://centrix.wokan.com.ar/acbytoken/${values.token}">
                Ver datos de la oportunidad en Wokan</a>.
            </p>
            <h2>Datos del interesado</h2>
            <dl>
              <dt>Nombre</dt>
              <dd>${data.nombre}</dd>
              <dt>Correo electrónico</dt>
              <dd>${data.email}</dd>
              <dt>Celular</dt>
              <dd>${data.telefono}</dd>
              <dt>Vehiculo</dt>
              <dd>
                ${values.marcaDescripcion} -
                ${values.versionDescripcion} -
                ${data.anio}
              </dd>
            </dl>
          `
          const params = { asunto, cuerpo, responder: data.email }
          fetch(`${process.env.WOKAN_WEBPACK_ENPOINT}/mensajes`, {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
              "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_SID}`,
              "Content-Type": "application/json",
            },
          })
        }
      })
      .catch(err => {
        errorCallback(
          "¿Está conectado a internet? Ocurrió un error y no se puede procesar su solicitud, intente nuevamente más tarde por favor."
        )
      })
  }

  render() {
    return (
      <Formik
        initialValues={{
          marca: "",
          anio: "",
          modelo: "",
          version: "",
          gnc: "0",
          cp: "",
          celular: "",
          email: "",
          nombre: "",
        }}
        validationSchema={() =>
          Yup.object().shape({
            marca: Yup.string().required("Requerido"),
            anio: Yup.number().required("Requerido"),
            modelo: Yup.string().required("Requerido"),
            version: Yup.string().required("Requerido"),
            nombre: Yup.string()
              .trim()
              .required("Requerido"),
            cp: Yup.number()
              .integer("Ingrese sólo números")
              .min(1000, "Inválido")
              .max(9999, "Inválido")
              .required("Requerido"),
            celular: Yup.string()
              .trim()
              .matches(/^\+549?\d{10}$/, "Ingrese código de área y número")
              .required("Requerido"),
            email: Yup.string()
              .email("Inválido")
              .required("Requerido"),
            gnc: Yup.boolean().required("Requerido"),
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          if (window.gtag) {
            window.gtag("event", "l2_boton_cotizar")
          }

          values.marcaDescripcion = this.state.marcas.find(
            marca => marca.id === values.marca
          ).descripcion
          values.versionDescripcion = this.state.versiones.find(
            version => version.id === values.version
          ).label

          this.cotizar(values, error => {
            alert(error)
            setSubmitting(false)
          })
        }}
      >
        {({ values, handleChange, handleBlur, setFieldValue, errors, touched, isSubmitting }) => (
          <Form className="pt-2">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="form-group">
                  <label className="text-primary">Marca</label>
                  <Field
                    name="marca"
                    component="select"
                    className={`form-control ${errors.marca && touched.marca && " is-invalid"}`}
                    innerRef={e => (this.marcaSelect = e)}
                    disabled={this.state.marcas.length === 0}
                    onChange={e => {
                      handleChange(e)
                      this.setState({
                        anios: [],
                        modelos: [],
                        versiones: [],
                      })
                      let marca = e.target.value
                      if (marca) {
                        fetch(
                          `${process.env.WOKAN_WEBPACK_ENPOINT}/data_auto/anios?filter[marca]=${marca}`,
                          {
                            headers: {
                              "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_SID}`,
                            },
                          }
                        )
                          .then(response => response.json())
                          .then(data => {
                            const anios = data.result.map(value => ({
                              anio: value.anio.toString(),
                              label: value.anio.toString(),
                            }))
                            if (new Date().getFullYear() === parseInt(anios[0].anio)) {
                              anios.unshift({ anio: "0", label: "0 km" })
                            }
                            this.setState({ anios })
                            this.anioSelect.focus()
                          })
                      }
                      setFieldValue("anio", "")
                      setFieldValue("modelo", "")
                      setFieldValue("version", "")
                      if (window.gtag) {
                        window.gtag("event", "l2_marca")
                      }
                    }}
                  >
                    <option />
                    {this.state.marcas
                      .filter(marca => marca.preferida)
                      .map(marca => (
                        <option key={marca.id} value={marca.id}>
                          {marca.descripcion}
                        </option>
                      ))}
                    <option value="" disabled>
                      ---
                    </option>
                    {this.state.marcas
                      .filter(marca => !marca.preferida)
                      .map(marca => (
                        <option key={marca.id} value={marca.id}>
                          {marca.descripcion}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage name="marca" component="div" className="invalid-feedback" />
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="form-group">
                  <label className="text-primary">Año</label>
                  <Field
                    name="anio"
                    component="select"
                    className={`form-control ${errors.anio && touched.anio && " is-invalid"}`}
                    innerRef={e => (this.anioSelect = e)}
                    disabled={this.state.anios.length === 0}
                    onChange={e => {
                      handleChange(e)
                      this.setState({
                        modelos: [],
                        versiones: [],
                      })
                      let anio = e.target.value
                      if (anio) {
                        anio = anio === "0" ? new Date().getFullYear() : anio
                        fetch(
                          `${process.env.WOKAN_WEBPACK_ENPOINT}/data_auto/grupos?filter[marca]=${values.marca}&filter[anio]=${anio}`,
                          {
                            headers: {
                              "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_SID}`,
                            },
                          }
                        )
                          .then(response => response.json())
                          .then(data => {
                            const modelos = data.result.map(value => ({
                              id: value.id,
                              label: value.descripcion,
                            }))
                            this.setState({ modelos })
                            this.modeloSelect.focus()
                          })
                      }
                      setFieldValue("modelo", "")
                      setFieldValue("version", "")
                      if (window.gtag) {
                        window.gtag("event", "l2_anio")
                      }
                    }}
                  >
                    <option />
                    {this.state.anios.map(anio => (
                      <option key={anio.anio} value={anio.anio}>
                        {anio.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="anio" component="div" className="invalid-feedback" />
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="form-group">
                  <label className="text-primary">Modelo</label>
                  <Field
                    name="modelo"
                    component="select"
                    className={`form-control ${errors.modelo && touched.modelo && " is-invalid"}`}
                    innerRef={e => (this.modeloSelect = e)}
                    disabled={this.state.modelos.length === 0}
                    onChange={e => {
                      handleChange(e)
                      this.setState({
                        versiones: [],
                      })
                      let modelo = e.target.value
                      if (modelo) {
                        let anio = values.anio === "0" ? new Date().getFullYear() : values.anio
                        fetch(
                          `${process.env.WOKAN_WEBPACK_ENPOINT}/data_auto/modelos?filter[marca]=${values.marca}&filter[anio]=${anio}&filter[grupo]=${modelo}`,
                          {
                            headers: {
                              "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_SID}`,
                            },
                          }
                        )
                          .then(response => response.json())
                          .then(data => {
                            const versiones = data.result.map(value => ({
                              id: value.id,
                              label: value.descripcion,
                            }))
                            this.setState({ versiones })
                            this.versionSelect.focus()
                          })
                      }
                      setFieldValue("version", "")
                      if (window.gtag) {
                        window.gtag("event", "l2_modelo")
                      }
                    }}
                  >
                    <option />
                    {this.state.modelos.map(modelo => (
                      <option key={modelo.id} value={modelo.id}>
                        {modelo.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="modelo" component="div" className="invalid-feedback" />
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="form-group">
                  <label className="text-primary">Versión</label>
                  <Field
                    name="version"
                    component="select"
                    className={`form-control ${errors.version && touched.version && " is-invalid"}`}
                    innerRef={e => (this.versionSelect = e)}
                    disabled={this.state.versiones.length === 0}
                    onChange={async e => {
                      handleChange(e)
                      if (window.gtag) {
                        window.gtag("event", "l2_version")
                      }
                    }}
                  >
                    <option />
                    {this.state.versiones.map(version => (
                      <option key={version.id} value={version.id}>
                        {version.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="version" component="div" className="invalid-feedback" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="form-group">
                  <label className="text-primary">Tiene GNC</label>
                  <Field
                    name="gnc"
                    className={`form-control ${errors.gnc && touched.gnc && " is-invalid"}`}
                    component="select"
                    onChange={async e => {
                      handleChange(e)
                      if (window.gtag) {
                        window.gtag("event", "l2_gnc")
                      }
                    }}
                  >
                    <option value="0">No</option>
                    <option value="1">Si</option>
                  </Field>
                  <ErrorMessage name="gnc" component="div" className="invalid-feedback" />
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="form-group">
                  <label className="text-primary">Código postal</label>
                  <Field
                    name="cp"
                    className={`form-control ${errors.cp && touched.cp && " is-invalid"}`}
                    type="number"
                    placeholder="Ej: 1704"
                    onBlur={async e => {
                      handleBlur(e)
                      if (window.gtag) {
                        window.gtag("event", "l2_cp")
                      }
                    }}
                  />
                  <ErrorMessage name="cp" component="div" className="invalid-feedback" />
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="form-group">
                  <label className="text-primary">Celular</label>
                  <Field name="celular">
                    {({ field, form, meta }) => (
                      <PhoneInput
                        name={field.name}
                        value={field.value}
                        onChange={val => setFieldValue(field.name, val)}
                        onBlur={async e => {
                          handleBlur(e)
                          if (window.gtag) {
                            window.gtag("event", "l2_celular")
                          }
                        }}
                        country="AR"
                        placeholder="Ej: 11 7777-8888"
                        className={`form-control ${errors.celular &&
                        touched.celular &&
                        " is-invalid"}`}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="celular" component="div" className="invalid-feedback" />
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="form-group">
                  <label className="text-primary">Email</label>
                  <Field
                    name="email"
                    className={`form-control ${errors.email && touched.email && " is-invalid"}`}
                    type="email"
                    onBlur={async e => {
                      handleBlur(e)
                      if (window.gtag) {
                        window.gtag("event", "l2_email")
                      }
                    }}
                  />
                  <ErrorMessage name="email" component="div" className="invalid-feedback" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-6">
                <div className="form-group">
                  <label className="text-primary">Nombre y apellido</label>
                  <Field
                    name="nombre"
                    className={`form-control ${errors.nombre && touched.nombre && " is-invalid"}`}
                    type="text"
                    onBlur={async e => {
                      handleBlur(e)
                      if (window.gtag) {
                        window.gtag("event", "l2_nombre")
                      }
                    }}
                  />
                  <ErrorMessage name="nombre" component="div" className="invalid-feedback" />
                </div>
              </div>

              <div className="col-lg-6 col-sm-6">
                <div className="form-group">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary btn-block mt-4"
                  >
                    {isSubmitting ? "Un momento por favor..." : "¡Ver mi cotización!"}
                  </button>
                  <div
                    className="g-recaptcha"
                    ref={e => {
                      this.recaptchaWidget = e
                    }}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    )
  }
}
